<template>
    <div class="all-users">
      <h1>Visi vartotojai</h1>
      <table v-if="users.length" class="users-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>El. paštas</th>
            <th>Vardas</th>
            <th>Pavardė</th>
            <th>Telefonas</th>
            <th>Sukūrimo data</th>
            <th>Aktyvus</th>
            <th>Miestas</th>
            <th>Adresas</th>
            <th>Pašto kodas</th>
            <th>Veiksmai</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.first_name }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.phone_number || '-' }}</td>
            <td>{{ user.created }}</td>
            <td>{{ user.is_active ? 'Taip' : 'Ne' }}</td>
            <td>{{ user.city || '-' }}</td>
            <td>{{ user.address || '-' }}</td>
            <td>{{ user.postal_code || '-' }}</td>
            <td>
              <button @click="viewUser(user.id)" class="view-button">Peržiūrėti</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="no-users">Nėra vartotojų.</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AllUsers',
    data() {
      return {
        users: [],
        loading: true,
        error: null,
      };
    },
    methods: {
      getAuthHeader() {
        const token = localStorage.getItem('adminToken');
        return { Authorization: `Bearer ${token}` };
      },
      async fetchUsers() {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/users`, {
            headers: this.getAuthHeader(),
          });
          this.users = response.data.users;
        } catch (error) {
          console.error('Klaida gaunant vartotojus:', error);
          this.error = 'Klaida gaunant vartotojus. Bandykite dar kartą.';
        } finally {
          this.loading = false;
        }
      },
      viewUser(userId) {
        console.log("called view user: ", userId)
        this.$router.push({ name: 'UserDetails', params: { id: userId } });
      },
    },
    mounted() {
      this.fetchUsers();
    },
  };
  </script>
  
  <style scoped>
  .all-users {
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    color: #2cb6d2;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
  }
  
  .users-table th,
  .users-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .users-table th {
    background-color: #2cb6d2;
    color: white;
  }
  
  .view-button {
    background-color: #2cb6d2;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .view-button:hover {
    background-color: #2493a7;
  }
  
  .no-users {
    text-align: center;
    color: red;
    font-size: 1.2em;
  }
  </style>
  