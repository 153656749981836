<template>
  <div class="admin-dashboard-container">
    <!-- Header -->
    <div class="header">
      <h1>Revolt admin</h1>
      <button @click="logout" class="btn-logout">Logout</button>
    </div>

    <!-- Tabs Navigation -->
    <div class="tabs">
      <button class="tab" :class="{ active: activeTab === 'products' }" @click="activeTab = 'products'">
        Produktai
      </button>
      <button class="tab" :class="{ active: activeTab === 'orders' }" @click="activeTab = 'orders'">
        Uzsakymai
      </button>
      <button class="tab" :class="{ active: activeTab === 'carts' }" @click="activeTab = 'carts'">
        Krepseliai
      </button>
      <button class="tab" :class="{ active: activeTab === 'users' }" @click="activeTab = 'users'">
        Vartotojai
      </button>
      <button class="tab" :class="{ active: activeTab === 'categories' }" @click="activeTab = 'categories'">
        Kategorijos
      </button>
      <button class="tab" :class="{ active: activeTab === 'conditions' }" @click="activeTab = 'conditions'">
        Buklė
      </button>
      <button class="tab" :class="{ active: activeTab === 'presets' }" @click="activeTab = 'presets'">
        Subkategoriju presetai
      </button>
    </div>

    <!-- Tab Content -->
    <div class="content">
      <div v-if="activeTab === 'products'">
        <AllProducts />
      </div>
      <div v-if="activeTab === 'orders'">
        <AllOrdersComponent />
      </div>
      <div v-if="activeTab === 'carts'">
        <AllCarts />
      </div>
      <div v-if="activeTab === 'users'">
        <AllUsers/>
      </div>
      <div v-if="activeTab === 'categories'">
        <CategoriesComponent />
      </div>
      <div v-if="activeTab === 'conditions'">
        <ConditionsComponent />
      </div>
      <div v-if="activeTab === 'presets'">
        <PresetComponent />
      </div>
    </div>
  </div>
</template>

<script>
import AllProducts from '@/components/productcs/AllProducts.vue';
import CategoriesComponent from '../categories/CategoriesComponent.vue';
import ConditionsComponent from './conditions/ConditionsComponent.vue';
import PresetComponent from '../presets/PresetComponent.vue';
import AllOrdersComponent from '../orders/AllOrdersComponent.vue';
import AllUsers from '../users/AllUsers.vue';
import AllCarts from '../carts/AllCarts.vue';

export default {
  name: 'AdminDashboard',
  components: {
    AllProducts,
    CategoriesComponent,
    ConditionsComponent,
    PresetComponent,
    AllOrdersComponent,
    AllUsers,
    AllCarts
  },
  data() {
    return {
      activeTab: 'products', // Default to the "Products" tab
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('adminToken');
      this.$router.push('/admin/login');
    },
  },
};
</script>

<style scoped>
.admin-dashboard-container {
  padding: 20px;
  font-family: 'Inter', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2cb6d2;
  padding: 10px 20px;
  color: #fff;
}

.btn-logout {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tabs {
  display: flex;
  margin-top: 20px;
  border-bottom: 1px solid #ddd;
}

.tab {
  background-color: #f0f2f4;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #e0e2e4;
}

.tab.active {
  background-color: #2cb6d2;
  color: white;
}

.content {
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: -1px;
}
</style>