<template>
  <div class="user-details">
    <div class="col-2 offset-1 button-container">
      <router-link :to="{ name: 'AdminDashboard' }" class="styled-button">Atgal</router-link>
    </div>
    <div class="user-details-container">
      <h1>Vartotojo informacija</h1>
      <div v-if="user" class="user-info">
        <div class="info-item">
          <strong>ID:</strong> <span>{{ user.id }}</span>
        </div>
        <div class="info-item">
          <strong>El. paštas:</strong> <span>{{ user.email }}</span>
        </div>
        <div class="info-item">
          <strong>Vardas:</strong> <span>{{ user.first_name }}</span>
        </div>
        <div class="info-item">
          <strong>Pavardė:</strong> <span>{{ user.last_name }}</span>
        </div>
        <div class="info-item">
          <strong>Telefonas:</strong> <span>{{ user.phone_number || '-' }}</span>
        </div>
        <div class="info-item">
          <strong>Adresas:</strong> <span>{{ user.address || '-' }}</span>
        </div>
        <div class="info-item">
          <strong>Miestas:</strong> <span>{{ user.city || '-' }}</span>
        </div>
        <div class="info-item">
          <strong>Pašto kodas:</strong> <span>{{ user.postal_code || '-' }}</span>
        </div>
      </div>

      <div class="orders-section">
        <h2>Užsakymai</h2>
        <div v-if="orders.length" class="orders-list">
          <div
            v-for="order in orders"
            :key="order.id"
            :class="orderClass(order.payment_status)"
            class="order-card"
            @click="redirectToOrderEdit(order.id)"
          >
            <h3>Užsakymas #{{ order.id }}</h3>
            <p><strong>Statusas:</strong> {{ order.status }}</p>
            <p><strong>Apmokėjimo būsena:</strong> {{ order.payment_status }}</p>
            <p><strong>Sukurta:</strong> {{ formatDate(order.created) }}</p>
            <p><strong>Atnaujinta:</strong> {{ formatDate(order.updated) }}</p>
            <p><strong>Bendra suma:</strong> {{ order.order_total }}€</p>
          </div>
        </div>
        <p v-else class="no-orders">Šis vartotojas neturi užsakymų.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserDetails",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: null,
      orders: [],
    };
  },
  methods: {
    getAuthHeader() {
      const token = localStorage.getItem("adminToken");
      return { Authorization: `Bearer ${token}` };
    },
    async fetchUserDetails(userId) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/admin/users/${userId}`,
          {
            headers: this.getAuthHeader(),
          }
        );
        this.user = response.data.user;
        this.orders = response.data.orders;
      } catch (error) {
        console.error("Klaida gaunant vartotojo informaciją:", error);
      }
    },
    formatDate(dateString) {
      if (!dateString) return "Neatnaujinta";

      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "Neteisinga data";

      return date.toLocaleDateString("lt-LT", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    orderClass(paymentStatus) {
      return paymentStatus === "confirmed"
        ? "order-confirmed"
        : "order-unpaid";
    },
    redirectToOrderEdit(id) {
      this.$router.push({ name: "OrderEdit", params: { id } });
    },
  },
  mounted() {
    const userId = this.$route.params.id;
    this.fetchUserDetails(userId);
  },
};
</script>

<style scoped>
.user-details {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
}

h1,
h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.user-details-container {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-info {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}

.info-item {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.orders-section {
  margin-top: 30px;
}

.orders-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.order-card {
  flex: 1 1 calc(33% - 15px);
  min-width: 250px;
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.order-card:hover {
  background-color: #d1cbcb;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

.order-card h3 {
  margin-bottom: 10px;
  color: #2cb6d2;
}

.order-card p {
  margin: 5px 0;
}

.no-orders {
  text-align: center;
  color: #777;
  font-size: 16px;
}

/* Conditional Styling for Orders */
.order-confirmed {
  background-color: #d4edda;
}

.order-unpaid {
  background-color: #fff3cd;
}

.styled-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2cb6d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.styled-button:hover {
  background-color: #2199b1;
}

.styled-button:active {
  background-color: #1a7d96;
}
</style>
