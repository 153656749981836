<template>
  <div class="admin-login-container">
    <div class="login-card">
      <h2 class="login-title">Revolt admin</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email" class="form-label">Pastas</label>
          <input
            type="email"
            v-model="email"
            id="email"
            class="form-control"
            placeholder="Enter your admin email"
            required
          />
        </div>

        <div class="form-group">
          <label for="password" class="form-label">Slaptikas</label>
          <input
            type="password"
            v-model="password"
            id="password"
            class="form-control"
            placeholder="Enter your password"
            required
          />
        </div>

        <button type="submit" class="btn-submit">
          Login
        </button>
      </form>

      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>

      <div v-if="success" class="alert alert-success">
        {{ success }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      email: "",
      password: "",
      error: null,
      success: null, // To hold the success message
    };
  },
  methods: {
    login() {
      this.error = null;
      this.success = null; // Clear any previous messages
      axios
        .post(`${API_BASE_URL}/api/login`, {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          const token = response.data.token;
          const decodedToken = VueJwtDecode.decode(token);

          const roles = decodedToken.roles;
          if (roles.includes("ROLE_ADMIN")) {
            this.success = "Sėkmingai prisijungėte! Perkeliame jus...";
            localStorage.setItem("adminToken", token);

            // Wait 1 second before redirecting
            setTimeout(() => {
              this.$router.push({ name: "AdminDashboard" });
            }, 1000);
          } else {
            this.error = "Access Denied: Not an Admin.";
          }
        })
        .catch(() => {
          this.error = "Invalid email or password.";
        });
    },
  },
};
</script>

<style scoped>
.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #f5f5f5;
  padding: 20px;
  height: 100%;
}

.login-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.login-title {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
  display: block;
  text-align: left;
  padding-left: 10px;
}

.form-control {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 1em;
}

.btn-submit {
  width: 100%;
  padding: 12px;
  font-size: 1.1em;
  font-weight: bold;
  color: #fff;
  background-color: #2cb6d2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #239bb0;
}

.alert {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}
</style>
