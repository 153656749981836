<template>
  <div class="product-sources">
    <div class="col-2 offset-1 button-container">
      <router-link :to="{ name: 'AdminDashboard' }" class="styled-button">Atgal</router-link>
    </div>

    <h1 class="title">Product Sources</h1>

    <div class="table-container">
      <h2>Aktyviu produktu sourcai</h2>
      <table class="sources-table">
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="source in sources.active" :key="source.id">
            <td>{{ source.id }}</td>
            <td>
              <a :href="source.source" target="_blank" rel="noopener noreferrer" class="source-link">
                {{ source.source }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-container">
      <h2>parduotu produktu sourcai</h2>
      <table class="sources-table">
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="source in sources.sold" :key="source.id">
            <td>{{ source.id }}</td>
            <td>
              <a :href="source.source" target="_blank" rel="noopener noreferrer" class="source-link">
                {{ source.source }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-container">
      <h2>Pasleptu produtktu sourcai</h2>
      <table class="sources-table">
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="source in sources.inactive" :key="source.id">
            <td>{{ source.id }}</td>
            <td>
              <a :href="source.source" target="_blank" rel="noopener noreferrer" class="source-link">
                {{ source.source }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <p v-if="sources.active.length === 0 && sources.sold.length === 0 && sources.inactive.length === 0" class="no-sources">
      No sources available.
    </p>
  </div>
</template>
<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "ProductSources",
  data() {
    return {
      sources: {
        active: [],
        sold: [],
        inactive: [],
      }, // Separate arrays for sources
    };
  },
  methods: {
    async fetchSources() {
      try {
        const token = localStorage.getItem("adminToken");
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const response = await axios.get(`${API_BASE_URL}/admin/product/sources`, { headers });

        // Map response to sources object
        this.sources.active = response.data.active_sources;
        this.sources.sold = response.data.sold_sources;
        this.sources.inactive = response.data.inactive_sources;
      } catch (error) {
        console.error("Error fetching product sources:", error);
      }
    },
  },
  mounted() {
    this.fetchSources();
  },
};
</script>
<style scoped>
.product-sources {
  font-family: "Roboto", sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 20px auto;
}

.title {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.table-container {
  margin-top: 20px;
}

.sources-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.sources-table th,
.sources-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.sources-table th {
  background-color: #2cb6d2;
  color: #fff;
}

.sources-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.sources-table tr:hover {
  background-color: #f1f1f1;
}

.source-link {
  text-decoration: none;
  color: #2cb6d2;
  font-size: 14px;
}

.source-link:hover {
  text-decoration: underline;
}

.no-sources {
  text-align: center;
  color: #666;
  font-size: 16px;
}
</style>
