<template>
  <div class="product-management">
    <div>
    Produktų statistika:
    <ul v-if="productStats">
      <li>Aktyvūs produktai: {{ productStats.active_products }}</li>
      <li>Parduoti produktai: {{ productStats.sold_products }}</li>
      <li>Paslėpti produktai: {{ productStats.hidden_products }}</li>
    </ul>
    <div v-else>Įkeliama...</div>
  </div>
    <div class="header-controls">

      <div v-if="selectedProducts.length > 0" style="display: flex;">
        <button class="btn btn-danger" @click="showBulkDeleteModal = true">
          Ištrinti pasirinktus produktus
        </button>
        <!-- Update Status Section -->
        <div class="status-change-container">
          <label for="status-select" class="status-label">Pakeisti statusą į</label>
          <select v-model="newStatus" id="status-select" class="form-control status-select">
            <option value="">Pasirinkite statusą</option>
            <option value="1">aktyvus/matomas</option>
            <option value="2">parduotas</option>
            <option value="3">pasleptas</option>
          </select>
          <button @click="changeStatus" class="btn btn-primary status-change-btn"
            :disabled="!newStatus || selectedProducts.length === 0">
            Pakeisti
          </button>
        </div>
      </div>


      <button class="create-new-btn" @click="createNew()">
        Sukurti naują produktą <i class="fa-solid fa-plus"></i>
      </button>
      
      <button class="prod-src-btn" @click="gotToProductSources()">
        Visi produktu sourcai
      </button>
    </div>
    <table v-if="productList" class="table table-hover">
      <thead>
        <tr>
          <th>
            <input type="checkbox" class="form-check-input visibility-checkbox" @change="toggleSelectAll" />
          </th>
          <th>ID</th>
          <th>Statusas</th>
          <th>Produktas</th>
          <th>Kaina</th>
          <th>Subkategorija</th>
          <th>Sukurta</th>
          <th>Veiksmai</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in productList" :key="product.id" class="product-row"
          :class="getStatusClass(product.status)">
          <td>
            <input type="checkbox" class="form-check-input visibility-checkbox" :value="product.id"
              v-model="selectedProducts" />
          </td>
          <td>{{ product.id }}</td>
          <td>{{ getStatusText(product.status) }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.price }} €</td>
          <td>{{ product.subcategory }}</td>
          <td>{{ product.created }}</td>
          <td>
            <button class="btn-action btn-edit" style="margin-right: 1rem;"
              @click="goToEdit(product.id)">Redaguoti</button>
            <button class="btn-action btn-delete" @click="openDeleteModal(product)">Ištrinti</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="loading">
      <p>Kraunasi produktai...</p>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Patvirtinti ištrynimą</h5>
          <button type="button" class="btn-close" @click="showDeleteModal = false">×</button>
        </div>
        <div class="modal-body">
          Ar tikrai norite ištrinti produktą: <strong>{{ productToDelete?.name }}</strong>?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="showDeleteModal = false">Atšaukti</button>
          <button type="button" class="btn btn-danger" @click="confirmDelete">Ištrinti</button>
        </div>
      </div>
    </div>

    <!-- Bulk Delete Confirmation Modal -->
    <div v-if="showBulkDeleteModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Patvirtinti masinį ištrynimą</h5>
          <button type="button" class="btn-close" @click="showBulkDeleteModal = false">×</button>
        </div>
        <div class="modal-body">
          Ar tikrai norite ištrinti pasirinktus produktus? Šio veiksmo atšaukti negalima.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="showBulkDeleteModal = false">Atšaukti</button>
          <button type="button" class="btn btn-danger" @click="confirmBulkDelete">Ištrinti visus</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "AllProducts",
  data() {
    return {
      productStats: null,
      productList: null,
      productToDelete: null,
      selectedProducts: [],
      showDeleteModal: false,
      showBulkDeleteModal: false,
      newStatus: "", // Added for updating the status
    };
  },
  methods: {
    async fetchProductStatistics() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/product-stats`
        );
        this.productStats = response.data;
      } catch (error) {
        console.error('Klaida gaunant produktų statistiką:', error);
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return "aktyvus";
        case 2:
          return "parduotas";
        case 3:
          return "pasleptas";
        default:
          return "nežinomas"; // for unknown status
      }
    },
    getStatusClass(status) {
      switch (status) {
        case 1:
          return 'status-active';
        case 2:
          return 'status-sold';
        case 3:
          return 'status-hidden';
        default:
          return '';
      }
    },
    goToEdit(id) {
      this.$router.push({ name: "ProductEdit", params: { id } });
    },
    openDeleteModal(product) {
      this.productToDelete = product;
      this.showDeleteModal = true;
    },
    confirmDelete() {
      if (this.productToDelete) {
        axios
          .delete(`${API_BASE_URL}/product/${this.productToDelete.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.productList = this.productList.filter(
                (product) => product.id !== this.productToDelete.id
              );
            }
            this.showDeleteModal = false;
          })
          .catch((error) => {
            console.log("deletion error: ", error);
          });
      }
    },
    confirmBulkDelete() {
      axios
        .post(`${API_BASE_URL}/api/products/bulk-delete`, {
          ids: this.selectedProducts,
        })
        .then(() => {
          this.productList = this.productList.filter(
            (product) => !this.selectedProducts.includes(product.id)
          );
          this.selectedProducts = [];
          this.showBulkDeleteModal = false;
        })
        .catch((error) => {
          console.error("Error during bulk deletion:", error);
        });
    },

    toggleSelectAll(event) {
      if (event.target.checked) {
        this.selectedProducts = this.productList.map((product) => product.id);
      } else {
        this.selectedProducts = [];
      }
    },
    createNew() {
      this.$router.push({ name: "NewProduct" });
    },
    gotToProductSources() {
      this.$router.push({ name: "ProductSources" });
    },
    async fetchProducts() {
      const token = localStorage.getItem("adminToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await axios.get(`${API_BASE_URL}/products-page-admin`, { headers });
      this.productList = response.data.products.sort((a, b) => a.status - b.status);
    },
    changeStatus() {
      if (!this.newStatus || this.selectedProducts.length === 0) return;

      axios.post(`${API_BASE_URL}/product/update-status`, {
        ids: this.selectedProducts,
        status: this.newStatus
      })
        .then(() => {
          this.fetchProducts(); // Refresh the product list to reflect the updated statuses
          this.selectedProducts = [];
          this.newStatus = ""; // Reset the selected status
        })
        .catch((error) => {
          console.error("Error updating statuses:", error);
        });
    },
  },
  mounted() {
    this.fetchProducts();
  },
  created(){
    this.fetchProductStatistics();
  }
};
</script>

<style scoped>
/* Modal Styling */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.modal-header,
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.status-active {
  background-color: #cce5ff !important;
  /* Light blue for active */
}

.status-sold {
  background-color: #d4edda !important;
  /* Light green for sold */
}

.status-hidden {
  background-color: #fff3cd !important;
  /* Light yellow for hidden */
}

.product-management {
  padding: 20px;
}

.header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 60px !important;
}

.create-new-btn {
  background-color: rgb(37, 133, 37);
  color: white;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prod-src-btn {
  background-color: rgb(138, 106, 0);
  color: white;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-new-btn:hover {
  background-color: rgb(30, 107, 30);
}

.table {
  width: 100%;
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0 10px;
  text-align: center;
}

thead th {
  background-color: #2cb6d2;
  color: white;
  padding: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.product-row {
  border-radius: 8px;
  transition: box-shadow 0.3s;
}

.product-row:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.product-row td {
  border-bottom: none;
}

.btn-action {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
}

.btn-edit {
  background-color: #2cb6d2;
  color: white;
}

.btn-edit:hover {
  background-color: #239bb0;
}

.btn-delete {
  background-color: #ff4d4f;
  color: white;
}

.btn-delete:hover {
  background-color: #d9363e;
}

.loading p {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  padding: 20px;
}

.btn-danger {
  background-color: #dc3545;
  /* Bright red */
  color: #ffffff;
  /* White text */
  font-weight: bold;
  /* Bold text */
  padding: 10px 20px;
  /* Spacing */
  border: none;
  /* No border */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor */
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth transitions */
}

.btn-danger:hover {
  background-color: #c82333;
  /* Darker red for hover effect */
  transform: translateY(-2px);
  /* Slight lift on hover */
}

.btn-danger:active {
  background-color: #bd2130;
  /* Even darker red on click */
  transform: translateY(0);
  /* Remove lift effect on click */
}

.btn-danger:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: 0 0 0 4px rgba(220, 53, 69, 0.3);
  /* Custom focus shadow */
}

.status-change-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 2rem;
  padding: 10px 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.status-label {
  font-weight: bold;
  color: #333;
}

.status-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  transition: border-color 0.2s;
}

.status-select:focus {
  border-color: #2cb6d2;
  outline: none;
}

.status-change-btn {
  padding: 8px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.status-change-btn:disabled {
  background-color: #cfd8dc;
  cursor: not-allowed;
}

.status-change-btn:not(:disabled):hover {
  background-color: #2396ae;
  transform: translateY(-2px);
}




/* Custom checkbox styling */
.visibility-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #ddd;
  border: 2px solid #2cb6d2;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.visibility-checkbox:checked {
  background-color: #2cb6d2;
  border-color: #2396ae;
}

.visibility-checkbox:checked::after {
  content: "✓";
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.visibility-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(44, 182, 210, 0.3);
}
</style>
