// import { createApp } from 'vue'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import App from './App.vue'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import router from './router';

// createApp(App).use(router).mount('#app');


import { createApp } from 'vue';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import App from './App.vue';
// import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
// import 'bootstrap';


// Set the Axios base URL
axios.defaults.baseURL = 'http://localhost:8000';

// Create the app instance
const app = createApp(App);

// Register Axios globally
app.config.globalProperties.$axios = axios;

// Use the router and mount the app
app.use(router).mount('#app');
