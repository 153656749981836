import VueJwtDecode from "vue-jwt-decode";

export function isTokenExpired(token) {
  console.log("checking if token is expired")
  if (!token) {
    handleTokenExpiration();
    return true;
  }

  try {
    const { exp } = VueJwtDecode.decode(token); // Destructure 'exp' directly from the decoded token
    const currentTime = Math.floor(Date.now() / 1000); // Avoid using floating-point division
    console.log("Current time: ", currentTime);
    console.log("exr form JTW: ", exp);

    if (exp < currentTime) {
      handleTokenExpiration();
      return true;
    }
  } catch (error) {
    // Handle token decoding errors (e.g., if the token is malformed)
    console.error("Error decoding token:", error);
    handleTokenExpiration();
    return true;
  }

  return false;
}

function handleTokenExpiration() {
  localStorage.removeItem("adminToken");
  window.location.reload();
}
