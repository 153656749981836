<template>
    <div class="categories-component">
        <h2>Categories</h2>
        <button @click="addCategory" class="add-category-btn">Add New Category</button>

        <!-- Notification Messages -->
        <div v-if="message" :class="['notification', message.type]">
            {{ message.text }}
        </div>

        <!-- Loading Indicator -->
        <div v-if="loading" class="loading">Loading categories...</div>

        <!-- Categories Table -->
        <div v-else class="categories-table">
            <div v-for="category in categories" :key="category.id" class="category-group">
                <div class="category-header">
                    <div style="display: flex; flex-direction: row">
                        <div style="width: 5rem;">
                            <i :class="['fa', category.icon]" class="category-icon"></i>
                        </div>
                        <div style="width: 15rem;">
                            <span v-if="editingCategoryId !== category.id" class="category-name">{{ category.name
                                }}</span>
                            <input v-if="editingCategoryId === category.id" v-model="categoryForm.name"
                                class="edit-field" placeholder="Category Name" />
                        </div>
                        <div style="width: 10rem;">
                            <span v-if="editingCategoryId !== category.id" class="category-icon-text">{{ category.icon
                                }}</span>
                            <input v-if="editingCategoryId === category.id" v-model="categoryForm.icon"
                                class="edit-field" placeholder="Icon" />
                        </div>
                    </div>
                    <div class="category-actions">
                        <button v-if="editingCategoryId === category.id" @click="saveCategory(category)">Save</button>
                        <button v-if="editingCategoryId === category.id" @click="cancelEditing">Cancel</button>
                        <button v-else @click.stop="editCategory(category)">Edit</button>
                        <button @click.stop="confirmDeleteCategory(category)">Delete</button>
                        <button @click.stop="addSubcategory(category.id)" class="add-subcategory-btn">+</button>
                    </div>
                </div>

                <!-- Subcategories List (always displayed) -->
                <div class="subcategories-list">
                    <div v-for="subcategory in category.subcategories" :key="subcategory.id" class="subcategory-item">
                        <span v-if="editingSubcategoryId !== subcategory.id">{{ subcategory.name }}</span>
                        <input v-if="editingSubcategoryId === subcategory.id" v-model="subcategoryForm.name"
                            class="edit-field" placeholder="Subcategory Name" />
                        <div class="subcategory-actions">
                            <button v-if="editingSubcategoryId === subcategory.id"
                                @click="saveSubcategory(subcategory, category)">Save</button>
                            <button v-if="editingSubcategoryId === subcategory.id"
                                @click="cancelSubcategoryEditing">Cancel</button>
                            <button v-else @click="editSubcategory(subcategory)">Edit</button>
                            <button @click="confirmDeleteSubcategory(subcategory, category)">Delete</button>
                        </div>``
                    </div>
                </div>
            </div>
        </div>

        <!-- Confirmation Modal for Deleting Category -->
        <div v-if="showDeleteModal" class="modal-backdrop">
            <div class="modal">
                <div class="modal-content">
                    <p v-if="categoryToDelete?.subcategories.length > 0">
                        This category has subcategories. Deleting it will remove all its subcategories as well. Are you
                        sure?
                    </p>
                    <p v-else>
                        Are you sure you want to delete this category?
                    </p>
                    <div class="modal-btn-container">
                        <button @click="deleteCategoryConfirmed(true)">Yes</button>
                        <button @click="closeDeleteModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- Confirmation Modal for Deleting Subcategory -->
        <div v-if="showDeleteSubcategoryModal" class="modal-backdrop">
            <div class="modal">
                <div class="modal-content">
                    <p>Are you sure you want to delete the subcategory: <strong>{{ subcategoryToDelete?.name
                            }}</strong>?</p>
                    <div class="modal-btn-container">
                        <button @click="deleteSubcategoryConfirmed">Yes</button>
                        <button @click="closeDeleteSubcategoryModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import "@/styles/category_styles.css";

export default {
    name: 'CategoriesComponent',
    data() {
        return {
            categories: [],
            editingCategoryId: null,
            editingSubcategoryId: null,
            categoryForm: { id: null, name: '', icon: '', isNew: false },
            subcategoryForm: { id: null, name: '', isNew: false },
            loading: true,
            message: null,
            showDeleteModal: false,
            categoryToDelete: null,
            showDeleteSubcategoryModal: false,
            subcategoryToDelete: null,
            categoryOfSubcategoryToDelete: null,
        };
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/categories`);
                this.categories = response.data;
            } catch (error) {
                this.showMessage("Error loading categories", "error");
            } finally {
                this.loading = false;
            }
        },
        addCategory() {
            const newCategory = { id: Date.now(), name: "", icon: "", subcategories: [], isNew: true };
            this.categories.push(newCategory);
            this.editingCategoryId = newCategory.id;
            this.categoryForm = { ...newCategory };
        },
        editCategory(category) {
            this.editingCategoryId = category.id;
            this.categoryForm = { ...category, isNew: false };
        },
        async saveCategory(category) {
            try {
                if (this.categoryForm.isNew) {
                    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/category/create`, {
                        name: this.categoryForm.name,
                        icon: this.categoryForm.icon,
                    });
                    category.id = response.data.id;
                    category.isNew = false;
                } else {
                    await axios.put(`${process.env.VUE_APP_API_BASE_URL}/category/edit/${category.id}`, {
                        name: this.categoryForm.name,
                        icon: this.categoryForm.icon,
                    });
                }

                category.name = this.categoryForm.name;
                category.icon = this.categoryForm.icon;
                this.editingCategoryId = null;
                this.fetchCategories();
                this.showMessage("Category saved successfully", "success");
            } catch (error) {
                this.showMessage("Error updating category", "error");
            }
        },
        cancelEditing() {
            if (this.categoryForm.isNew) {
                this.categories = this.categories.filter(c => c.id !== this.categoryForm.id);
            }
            this.editingCategoryId = null;
        },
        confirmDeleteCategory(category) {
            console.log("called delete category on: ", category)
            this.categoryToDelete = category;
            this.showDeleteModal = true;
        },
        async deleteCategoryConfirmed(cascadeDelete = false) {
            if (this.categoryToDelete) {
                try {
                    await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/category/delete/${this.categoryToDelete.id}`, {
                        data: { cascadeDelete }
                    });
                    this.categories = this.categories.filter(c => c.id !== this.categoryToDelete.id);
                    this.showMessage("Category deleted successfully", "success");
                } catch (error) {
                    this.showMessage("Error deleting category", "error");
                } finally {
                    this.closeDeleteModal();
                }
            }
        },
        closeDeleteModal() {
            this.showDeleteModal = false;
            this.categoryToDelete = null;
        },
        addSubcategory(categoryId) {
            const category = this.categories.find(c => c.id === categoryId);
            if (category) {
                const newSubcategory = { id: Date.now(), name: "", isNew: true };
                category.subcategories.push(newSubcategory);
                this.editingSubcategoryId = newSubcategory.id;
                this.subcategoryForm = { id: newSubcategory.id, name: "", isNew: true };
                this.showMessage("New subcategory added", "success");
            }
        },
        editSubcategory(subcategory) {
            this.editingSubcategoryId = subcategory.id;
            this.subcategoryForm = { ...subcategory, isNew: false };
        },
        async saveSubcategory(subcategory, category) {
            try {
                if (this.subcategoryForm.isNew) {
                    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/subcategory/create`, {
                        name: this.subcategoryForm.name,
                        category_id: category.id,
                    });
                    subcategory.id = response.data.id;
                    subcategory.isNew = false;
                } else {
                    await axios.put(`${process.env.VUE_APP_API_BASE_URL}/subcategory/edit/${subcategory.id}`, {
                        name: this.subcategoryForm.name,
                        category_id: category.id,
                    });
                }

                subcategory.name = this.subcategoryForm.name;
                this.editingSubcategoryId = null;
                this.showMessage("Subcategory saved successfully", "success");
            } catch (error) {
                this.showMessage("Error updating subcategory", "error");
            }
        },
        cancelSubcategoryEditing() {
            if (this.subcategoryForm.isNew) {
                for (let category of this.categories) {
                    category.subcategories = category.subcategories.filter(sub => sub.id !== this.subcategoryForm.id);
                }
            }
            this.editingSubcategoryId = null;
        },
        confirmDeleteSubcategory(subcategory, category) {
            this.subcategoryToDelete = subcategory;
            this.categoryOfSubcategoryToDelete = category;
            this.showDeleteSubcategoryModal = true;
        },
        async deleteSubcategoryConfirmed() {
    if (this.subcategoryToDelete && this.categoryOfSubcategoryToDelete) {
        try {
            await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/subcategory/delete/${this.subcategoryToDelete.id}`);
            this.categoryOfSubcategoryToDelete.subcategories = this.categoryOfSubcategoryToDelete.subcategories.filter(
                (sub) => sub.id !== this.subcategoryToDelete.id
            );
            this.showMessage("Subcategory deleted successfully", "success");
        } catch (error) {
            const serverMessage = error.response?.data?.error || "An unexpected error occurred while deleting the subcategory.";
            this.showMessage(`Error deleting subcategory: ${serverMessage}`, "error");
        } finally {
            this.closeDeleteSubcategoryModal();
        }
    }
}
,
        closeDeleteSubcategoryModal() {
            this.showDeleteSubcategoryModal = false;
            this.subcategoryToDelete = null;
            this.categoryOfSubcategoryToDelete = null;
        },
        showMessage(text, type) {
            this.message = { text, type };
            setTimeout(() => {
                this.message = null;
            }, 3000);
        },
    },
    mounted() {
        this.fetchCategories();
    },
};
</script>

<style scoped>
.modal-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: auto;
    gap: 20px
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modal {
    width: 100%;
    max-width: 400px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    position: absolute;
    display: block;
    top: 30%;
    left: 42%;
    height: 170px;
}

.modal-content {
    padding: 20px;
    text-align: center;
}

.modal-footer {
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.modal-footer button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-footer .confirm-btn {
    background-color: #dc3545;
    color: #fff;
}

.modal-footer .confirm-btn:hover {
    background-color: #c82333;
}

.modal-footer .cancel-btn {
    background-color: #6c757d;
    color: #fff;
}

.modal-footer .cancel-btn:hover {
    background-color: #5a6268;
}
</style>
